import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { FC, useMemo } from 'react';
import Container from '~/components/Container';
import Icon from '~/components/Icon';
import SectionTitle from '~/components/SectionTitle';
import Text from '~/components/Text';
import Colors from '~/constants/colors';
import { ProductListKey } from '~/constants/enum';
import { QUERY_OPTIMISTIC_BESTSELLERS } from '~/data/home';
import { Product } from '~/services/product';
import { transformProductItems } from '~/utils/transform';
import ProductsSlider from '../../components/ProductsSlider';

interface BestSellerProps {
    title?: string;
    directLabel?: string;
    directUrl?: string;
    loading?: boolean;
    numberOfItems?: number;
    numberOfRows?: number;
    data?: Product[];
}

const BestSeller: FC<BestSellerProps> = ({
    title = 'BEST SELLER',
    directLabel = '',
    directUrl = '',
    numberOfItems = 4,
    numberOfRows = 1,
    data = [],
    loading
}) => {
    const {
        data: bestSellerProducts,
        loading: fecthLoading,
        called,
        networkStatus,
        fetchMore
    } = useQuery(QUERY_OPTIMISTIC_BESTSELLERS, {
        skip: !!data.length,
        context: { fetchOptions: { method: 'GET' } },
        notifyOnNetworkStatusChange: true,
        onError: ({ message }) => console.error(message, 'QUERY_OPTIMISTIC_BESTSELLERS')
    });

    const _PageInfo = useMemo(() => {
        if (!isEmpty(data)) return data;

        return bestSellerProducts?.bestSellerProducts?.page_info || {};
    }, [data, bestSellerProducts?.bestSellerProducts?.page_info]);
    const _data = useMemo(() => {
        if (!isEmpty(data)) return data;

        return transformProductItems(bestSellerProducts?.bestSellerProducts?.items) || [];
    }, [data, bestSellerProducts?.bestSellerProducts?.items]);

    const handleFetchMore = (current: number) => {
        if (current <= _PageInfo?.total_pages) {
            fetchMore({
                variables: {
                    currentPage: bestSellerProducts?.bestSellerProducts?.page_info?.current_page + 1
                },
                updateQuery(previousQueryResult, options) {
                    const { fetchMoreResult } = options;
                    if (!fetchMoreResult) return previousQueryResult;
                    return Object.assign({}, previousQueryResult, {
                        bestSellerProducts: {
                            ...fetchMoreResult.bestSellerProducts,
                            items: [
                                ...previousQueryResult.bestSellerProducts.items,
                                ...fetchMoreResult.bestSellerProducts.items
                            ]
                        }
                    });
                }
            });
        }
    };

    const _loading = loading || fecthLoading;
    if (!_data?.length && !_loading) {
        return null;
    }

    return (
        <Container className="flex flex-col gap-2 lg:gap-2">
            <SectionTitle
                text={title}
                textProps={{ type: 'headline-3', weight: 800, color: Colors.GREY_900 }}
                action={
                    directUrl ? (
                        <Link href={directUrl} className="flex items-center gap-1 text-primary">
                            <Text type="title-2">{directLabel}</Text>
                            <Icon name="arrow-up-right" />
                        </Link>
                    ) : undefined
                }
            />
            <ProductsSlider
                listkey={ProductListKey.BestSeller}
                products={_data}
                hasNext={_PageInfo.current_page < _PageInfo.total_pages}
                onLoadingMore={() => handleFetchMore(_PageInfo.current_page + 1)}
                loading={_loading}
            />
        </Container>
    );
};

export default BestSeller;
